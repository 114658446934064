import * as React from "react"
import Header from "./header"
import * as styles from "./layoutStyles.module.scss"
import { Nav } from "./navBar"
import scrollTo from "gatsby-plugin-smoothscroll"
import { GatsbyImage } from "gatsby-plugin-image"
import { restructureNodesForPictures } from "../utils/helpers"

const Post = ({ title, pictures, date }) => {
  const titleWithSpaces = title.replace(/-/g, " ")

  return (
    <div className={styles.post}>
      <>
        <h2 className={styles.postTitle}>{titleWithSpaces}</h2>
        <p className={styles.postDate}>{date}</p>

        {pictures.map((picture, i) => (
          <GatsbyImage
            className={styles.postBody}
            image={picture.childImageSharp.gatsbyImageData}
            alt={""}
            key={i}
          />
        ))}
      </>
    </div>
  )
}

const Layout = ({ data }) => {
  const {
    allMarkdownRemark: { nodes: infoNodes },
  } = data
  const {
    allS3ImageAsset: { nodes: pictureNodes },
  } = data

  const infoAndPics = restructureNodesForPictures(infoNodes, pictureNodes)

  infoAndPics.map(category => {
    category.pictures.sort((a, b) => a.Key.localeCompare(b.Key))
  })

  return (
    <>
      <Header />
      <div className={styles.layout}>
        <div className={styles.postsContainer}>
          {infoAndPics.map(({ frontmatter, fields, pictures }, i) => (
            <Post
              key={i}
              slug={fields.slug}
              title={frontmatter.title}
              pictures={pictures}
              date={frontmatter.date}
            />
          ))}
        </div>
        <Nav> </Nav>
      </div>
      <div className={styles.bttContainer}>
        <button
          className={styles.btpButton}
          onClick={() => scrollTo("#backToHeader")}
        >
          TOP
        </button>
      </div>
    </>
  )
}

export default Layout

/*
Sort pictures in alphabetical order, (filter on 'Key')

Remove old CSS that deals with image sizes
Add optimise image stuff

AWS billing?
Error page

Find old pictures?
Add GA

pagespeedinsights google
 */
