export const restructureNodesForPictures = (infoNodes, pictureNodes) => {
  return infoNodes.reduce((acc, currentValue) => {
    const infoAndPictures = {}
    pictureNodes.forEach(pic => {
      if (pic.Key.includes(currentValue.frontmatter.bucketDir)) {
        let addToAcc = true
        acc.map(accInfoAndPics => {
          if (
            accInfoAndPics.frontmatter.title === currentValue.frontmatter.title
          ) {
            addToAcc = false
            return accInfoAndPics.pictures.push(pic)
          } else {
            return accInfoAndPics
          }
        })
        if (addToAcc) {
          infoAndPictures["fields"] = { ...currentValue.fields }
          infoAndPictures["frontmatter"] = { ...currentValue.frontmatter }
          infoAndPictures["pictures"] = [pic]
          acc.push(infoAndPictures)
        }
      }
    })
    return acc
  }, [])
}
