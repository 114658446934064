import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

/*filter directly on the query
How to get the pictures in tag categories?
why am I using tags as an array?
 */

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "LL")
            tags
            bucketDir
          }
        }
      }
    }
    allS3ImageAsset {
      nodes {
        Key
        childImageSharp {
          gatsbyImageData(
            quality: 100
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`
const findPicturesWithTag = data => {
  const tag = data.allMarkdownRemark.edges[0].node.frontmatter.tags[0]
  return data.allS3ImageAsset.nodes.reduce((acc, val) => {
    if (val.Key.includes(tag)) {
      acc.push(val)
    }
    return acc
  }, [])
}

const restructureAllPosts = data => {
  return data.allMarkdownRemark.edges.reduce((acc, val) => {
    acc.push(val.node)
    return acc
  }, [])
}
//Scotland doesn't work. BucketDir out of wack. Reshuffle on AWS

const Tags = ({ data }) => {
  const restructurePosts = restructureAllPosts(data)
  const allPicturesWithTag = findPicturesWithTag(data)

  const postData = {
    allMarkdownRemark: { nodes: restructurePosts },
    allS3ImageAsset: { nodes: allPicturesWithTag },
  }

  return <Layout data={postData} />
}

export default Tags
